import { createInnerwellApiClient } from '@innerwell/api-client';
import { account } from '@innerwell/contracts';
import {
   type JwtPayloadApiObject,
   type MyPatientInsurance,
   type MyPatientResponseDto,
} from '@innerwell/dtos';
import { getCookieSession } from '@innerwell/utils';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type SessionUser = {
   sub: string;
   'cognito:username': string;
   // jwtToken: string; // removed
   exp: number;
   given_name: string;
   family_name: string;
   email: string;
   phone_number: string;
   'custom:welkinUserId': string;
   'custom:formsortUUID': string | undefined;
   insurance: MyPatientInsurance | null;
};

// TODO: maybe use webApiClient instead of apiClient, but without redirect
const apiClient = createInnerwellApiClient(
   {
      baseURL: getClientPublicRuntimeConfig().apiUrl,
      // using token interceptor here, we will try to refresh the token if it's expired
      // but not using onRefreshTokenError, this will be handled by generateSessionContext
      useRefreshTokenInterceptors: true,
      withCredentials: true,
      getCookieExp() {
         return getCookieSession<{ exp: number }>('')?.exp;
      },
   },
   {
      account,
   },
);

export const getSession = async (
   client = apiClient,
): Promise<SessionUser | null> => {
   const sessionInfo = getCookieSession<JwtPayloadApiObject>('');

   if (!sessionInfo) {
      return null;
   }

   let patient: MyPatientResponseDto | null = null;
   try {
      const patientRes = await client.account.getPatient();
      patient = patientRes.body;
   } catch (err) {
      // handleSentryException(err);
   }

   if (!patient) {
      return null;
   }

   return {
      ...sessionInfo,
      given_name: patient.firstName,
      family_name: patient.lastName,
      phone_number: patient.phone,
      insurance: patient.insurance,
   };
};

const redirectBaseUrl = '/auth/login';

export type SessionContextValue = (
   | { data: SessionUser; status: 'authenticated' }
   | {
        data: null;
        status: 'unauthenticated' | 'loading';
     }
) & {
   clearSession: () => void;
   refetchSession: () => Promise<SessionUser | null>;
};

const SessionContext = createContext<SessionContextValue>({
   clearSession: () => {},
   refetchSession: async () => {
      return null;
   },
   data: null,
   status: 'loading',
});

export function SessionProvider(props: {
   children: React.ReactNode;
   session?: SessionUser | null;
}) {
   const { children } = props;

   const [session, setSession] = useState<SessionUser | null>(null);

   /** If session was passed, initialize as not loading */
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      setLoading(true);

      const getNewSession = async () => {
         const newSession = await getSession();
         setLoading(false);

         if (newSession) {
            setSession(newSession);
         }
      };

      if (!session) {
         getNewSession();
      }
   }, []);

   const value = useMemo(
      () =>
         ({
            data: session,
            status: loading
               ? 'loading'
               : session
                 ? 'authenticated'
                 : 'unauthenticated',
            refetchSession: async () => {
               const refetchedSession = await getSession();
               setSession(refetchedSession);

               return refetchedSession;
            },
            clearSession: () => {
               setSession(null);
            },
         }) as SessionContextValue,
      [session, loading],
   );

   return (
      <SessionContext.Provider value={value}>
         {children}
      </SessionContext.Provider>
   );
}

type UseSessionOptions = {
   required?: boolean;
   onUnauthenticated?: () => void;
};

export const useSession = (options?: UseSessionOptions) => {
   const value: SessionContextValue =
      useContext<SessionContextValue>(SessionContext);

   if (!value && process.env.NODE_ENV !== 'production') {
      throw new Error('`useSession` must be wrapped in a <SessionProvider />');
   }

   const { required, onUnauthenticated } = options ?? {};

   const requiredAndNotLoading = required && value.status === 'unauthenticated';

   useEffect(() => {
      if (requiredAndNotLoading) {
         const url = `${redirectBaseUrl ?? ''}?${new URLSearchParams({
            error: 'SessionRequired',
            callbackUrl: window.location.href,
         }).toString()}`;
         if (onUnauthenticated) onUnauthenticated();
         else window.location.href = url;
      }
   }, [onUnauthenticated, requiredAndNotLoading]);

   if (requiredAndNotLoading) {
      return {
         data: null,
         status: 'loading',
         clearSession: value.clearSession,
         refetchSession: value.refetchSession,
      } as const;
   }

   return value;
};
